<template>
  <div class="preview-layout">
    <section class="preview-sidebar">
      <Card title="基本信息">
        <Form ref="basic-info-form" :model="basicInfo" :label-width="50" :rules="basicInfoRules">
          <FormItem label="标题" prop="paperName">
            <Input v-model="basicInfo.paperName" placeholder="试卷标题" />
          </FormItem>
          <FormItem label="难度">
            <InputNumber placeholder="0 ~ 5" :min="0" :max="5" v-model="basicInfo.paperLevel" style="width: 100%" />
          </FormItem>
        </Form>
      </Card>
      <Card title="试题结构" style="marginTop: 10px;">
        <draggable v-model="questionSectionList" group="sectionList">
          <div class="paper-section-item" v-for="(item, index) in questionSectionList" :key="index + 'section'">
            <JYIcon href="#icon-guanbi1" class="close-btn" @click="handleDeleteSection(index)" />
            <Divider style="margin: 0">{{ item.name }}</Divider>
            <div class="paper-section-question-list">
              <draggable :list="item.questionVOS" group="questionList" class="draggable-preset">
                <Tag v-for="(question, qindex) in item.questionVOS" :key="index + qindex + 'question'">{{ qindex + 1 }}</Tag>
              </draggable>
            </div>
          </div>
        </draggable>
        <section style="display: flex;">
          <Button size="small" style="flex: 1; marginTop: 5px;" class="btn-extra-small" @click="toggleNewQuestionTypeModal">新增大题</Button>
          <Button
            size="small"
            style="flex: 1; marginTop: 5px; margin-left: 5px"
            class="btn-extra-small"
            @click="showSelectQuestionModal"
            :disabled="this.questionSectionList.length <= 0"
            >选小题</Button
          >
        </section>
      </Card>
      <Card style="margin-top: 10px;">
        <p>总题数: {{ totalQuestionCount }}</p>
        <p>总分: {{ totalScore }}</p>
        <Button type="primary" size="small" style="width: 100%" @click="handleSubmit" :disabled="isEdit" :loading="isSubmiting">
          {{ isSubmiting ? '正在提交' : '保存组卷' }}
        </Button>
      </Card>
    </section>
    <section class="preview-paper-content">
      <Card dis-hover>
        <h1 style="text-align: center;">{{ basicInfo.paperName }}</h1>
        <paper-view
          :sectionList="questionSectionList"
          :editable="true"
          @on-score-change="handleScoreChange"
          @on-question-delete="handleDeleteQuestion"
        />
      </Card>
    </section>
    <Modal title="输入新题型" v-model="showNewQuestionTypeModal" @on-ok="handleAddNewSection">
      <Input v-model="newSectionName" @on-enter="handleAddNewSection" />
      <div slot="footer">
        <Button @click="showNewQuestionTypeModal = false" class="modal-btn">取消</Button>
        <Button type="primary" @click="handleAddNewSection" class="modal-btn">确定</Button>
      </div>
    </Modal>
    <QuestionSelectModal :dataList="questionSectionList" v-model="questionSelectModalShow" @on-question-select="computeTotalScore" />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import JYIcon from '@components/common/JYIcon'
import PaperAPI from '@api/paper'
import QuestionSelectModal from './QuestionSelectModal.vue'
import PaperView from './PaperView.vue'
export default {
  props: {
    // 新增和编辑复用
    isEdit: {
      default: false
    }
  },
  mounted() {
    if (this.isEdit) {
      this.fetchEditData()
    }
  },
  data() {
    return {
      totalScore: 0,
      basicInfo: {
        paperName: '',
        paperLevel: 0
      },
      isSubmiting: false,
      questionSectionList: [],
      showNewQuestionTypeModal: false,
      newSectionName: '',
      basicInfoRules: {
        paperName: [{ required: true, message: '请输入试卷标题', trigger: 'blur' }]
      },
      questionSelectModalShow: false
    }
  },
  computed: {
    totalQuestionCount() {
      return this.questionSectionList.map(section => section.questionVOS.length).reduce((p, c) => p + c, 0)
    },
    questionList() {
      const ret = []
      for (let section of this.questionSectionList) {
        if (section && section.questionVOS instanceof Array) {
          Array.prototype.push.apply(ret, section.questionVOS)
        }
      }
      return ret
    }
  },
  methods: {
    async fetchEditData() {
      const { id } = this.$route.params
      const { res } = await PaperAPI.getAllPaperInfoById(id)
      this.basicInfo.paperName = res.name
      this.basicInfo.paperLevel = res.level || 0
      this.totalScore = res.totalPoints
      this.questionSectionList = res.sectionVOS
    },
    handleScoreChange(newList) {
      this.questionSectionList = newList
      this.computeTotalScore()
    },
    computeTotalScore() {
      const res = this.questionSectionList
        .map(section => section.questionVOS)
        .reduce((previousList, currentList) => previousList.concat(currentList))
        .map(question => Number.parseInt(question.points) || 0)
        .reduce((previousScore, currentScore) => previousScore + currentScore)
      this.totalScore = res
    },
    showSelectQuestionModal() {
      this.questionSelectModalShow = true
    },
    toggleNewQuestionTypeModal() {
      this.newSectionName = ''
      this.showNewQuestionTypeModal = true
    },
    handleAddNewSection() {
      this.showNewQuestionTypeModal = false
      const newSection = {
        name: this.newSectionName,
        questionVOS: []
      }
      this.questionSectionList.push(newSection)
    },
    handleDeleteSection(index) {
      this.questionSectionList.splice(index, 1)
    },
    handleDeleteQuestion(questionId) {
      this.questionSectionList.forEach((section, sIndex) => {
        if (section.questionVOS && section.questionVOS instanceof Array) {
          section.questionVOS.forEach((question, qIndex) => {
            if (question.questionId === questionId) {
              section.questionVOS.splice(qIndex, 1)
            }
          })
        }
      })
    },
    fetchQuestionInfo() {
      const questionId = this.$route.params.id
      PaperAPI.getAllPaperInfoById(questionId).then(({ res }) => {
        this.questionSectionList = res.sectionVOS
      })
    },
    processedFormData() {
      const ret = this.questionSectionList.map(section => ({
        name: section.name,
        questionForms: section.questionVOS.map(question => ({
          questionId: question.questionId,
          points: Number.parseInt(question.points) || 0
        }))
      }))
      return ret
    },
    async handleSubmit() {
      const isValid = await this.$refs['basic-info-form'].validate()
      if (!isValid) return
      if (this.questionSectionList.length === 0) {
        this.$message.error('请设置大题并选择小题')
        return
      }
      this.isSubmiting = true
      const bankId = Number.parseInt(this.$route.query['bankId']) || -1
      const { paperName, paperLevel } = this.basicInfo
      const formData = {
        name: paperName,
        paperBankId: bankId,
        level: paperLevel,
        status: 1,
        sectionForms: this.processedFormData()
      }
      try {
        await PaperAPI.createPaper(formData)
        this.$message.success('新建试卷成功')
        this.$router.push(`/manage/exam/paperbank/${bankId}`)
      } catch (e) {
      } finally {
        this.isSubmiting = false
      }
    }
  },
  components: {
    draggable,
    PaperView,
    QuestionSelectModal,
    JYIcon
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/variables.less';
.paper-section-item {
  cursor: move;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: solid 1px transparent;
  background: white;
  position: relative;
  &:hover {
    border: dashed 1px;
    & .close-btn {
      visibility: visible;
    }
  }
}
.paper-section-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-layout {
  text-align: start;
  display: flex;
  overflow-y: auto;
  height: 100%;
}
.preview-sidebar {
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  padding-right: 0;
  flex-direction: column;
  position: sticky;
  top: 0;
  // border-right: solid 1px gray;
}
.paper-section-question-list {
  display: flex;
}
.draggable-preset {
  min-height: 20px;
  width: 100%;
  text-align: start;
}
.paper-section-question-number {
  width: 30px;
  height: 30px;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-items: center;
  border: solid 1px black;
}
.paper-section-question-number + .paper-section-question-number {
  margin-left: 5px;
}
.paper-section-question-number:hover {
  background-color: @primary-hover-color;
  color: white;
}
.preview-paper-content {
  padding: 20px;
  padding-left: 10px;
  flex: 1;
  user-select: none;
  text-align: start;
}
.btn-extra-small {
  font-size: 12px;
}
.remove-zone {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  color: @primary-hover-color;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 1px @primary-hover-color;
  &:hover {
    border-color: @error-color;
    color: @error-color;
  }
}
.close-btn {
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
  cursor: pointer;
}
</style>
